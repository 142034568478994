<template>
  <section class="browse_categories_sec">
    <div class="container">
      <div class="browse_categories">
        <h2>
          {{ $t("categories") }}
          <span class="text-muted"> {{ getCategories.length }}</span>
        </h2>
        <h6 class="text-muted mt-2 mb-4">
          {{ $t("categoryDescriptionText") }}
        </h6>
        <div class="d-flex justify-content-end mb-4">
          <button
            @click="
              $router.replace({
                query: {
                  ...$route.query,
                  modal: 'add-category',
                },
              })
            "
            class="btn btn-pill donate"
          >
            + {{ $t("addNewCategory") }}
          </button>
        </div>
        <div class="text-center" v-if="categoriesAreLoading">
          <loading></loading>
        </div>
        <div class="row" v-else>
          <div
            class="col-lg-12 full_wdth"
            v-for="(item, i) in getCategories"
            :key="i"
          >
            <div class="p-2 bg-light card">
              <CategoryCard :item="item"></CategoryCard>
            </div>
            <!--br-channel end-->
          </div>
          <div
            v-if="getCategories.length === 0"
            class="d-flex w-100 justify-content-center"
          >
            <NoFound
              :buttonText="$t('addNewCategory')"
              link="categories?modal=add-category"
            ></NoFound>
          </div>
        </div>
      </div>
      <!--</div> end-->
    </div>
    <CreateCategory></CreateCategory>
  </section>
  <!--browse-sec end-->
</template>

<script>
import CategoryCard from "../Cards/CategoryCard.vue";
import Loading from "../Shared/Loading.vue";
import NoFound from "../Shared/NoFound.vue";
import CreateCategory from "./CreateCategory.vue";
export default {
  components: { CategoryCard, CreateCategory, NoFound, Loading },
  computed: {
    getCategories() {
      return this.$store.state.Category.categories;
    },
    categoriesAreLoading() {
      return this.$store.state.Category.categoriesAreLoading;
    },
  },
  mounted() {
    this.$store.dispatch("GET_CATEGORIES");
  },
};
</script>
