<template>
  <router-link :to="`category/${item.id}`" class="card hover flex-card row">
    <div class="col-4 col-lg-2">
      <img
        @error="(e) => (e.target.src = getSettings.defaultImage)"
        :src="$api.image(item.imagePath)"
        width="70"
        class="rounded"
        :alt="item.name"
      />
    </div>
    <div class="col-6 col-lg-4">
      <h6>{{ item.name }}</h6>
      <p class="sub-content">{{ item.description }}</p>
    </div>
    <div class="col-4 col-lg-3">
      <span class="text-primary">
        {{ $t("thereIsCountProductInCategory", { count: item.productCount }) }}
      </span>
    </div>
    <div class="col-6 col-lg-2">
      <router-link :to="`/category/${item.id}?modal=opened`"
        ><button class="btn btn-xs btn-block btn-pill subscribe">
          <i class="icon-pencil"></i> {{ $t("edit") }}
        </button></router-link
      >
      <router-link
        :to="`category/${item.id}`"
        class="mt-4 btn btn-pill btn-xs donate btn-block"
        >{{ $t("goToDetail") }} <i class="icon-next"></i
      ></router-link>
    </div>
  </router-link>
</template>

<script>
export default {
  computed: {
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  props: {
    item: {
      default: () => {},
    },
  },
};
</script>
