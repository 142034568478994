<template>
  <Categories></Categories>
</template>

<script>
import Categories from "../components/Categories";
export default {
  components: {
    Categories,
  },
};
</script>
